<template>
  <div>
    <vue-element-loading

      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      :is-full-screen="false"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout wrap v-else px-5 j justify-end>
      <v-flex pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <span>SIZE CHART</span>
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 pt-5>
        <ImageComp
        :singleImage="sizeChart"
          @stepper="winStepper"
          :height="3"
          :width="4"
          :heading="'Upload Size Chart (Aspect Ratio: 0/6)'"
          :componentType="'sizeChart'"
        />
      </v-flex>
      <v-flex zs6 sm4 md3 lg3 text-right pt-3>
        <v-btn
          :disabled="!sizeChart ? true : false"
          color="blue darken-1"
          @click="uploadImage"
        >
          <span style="color: #fff">Save</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  props: ["Name"],
  data() {
    return {
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      sizeChart: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "sizeChart") {
        this.sizeChart = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/sizechart/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.sizeChart= response.data.data.value;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("image", this.sizeChart);
      axios({
        method: "POST",
        url: "/sizechart/uploadimagesingle",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.sizeChart = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>